<template>
  <div class="app-install-success">
    <img src="../../../assets/images/shoplazza/success.png" class="success" />
    <div class="congratulations">Congratulations!</div>
    <div class="tips">
      <div>Onerway is activated in your store. </div>
      <div style="margin-top: 20px;">Get support from Onerway team - merchant@pacypay.com.</div>
      <div style="margin-top: 20px;">Please wait for 3s, it will automatically jump to the activation page ......</div>
    </div>
  </div>
</template>
<script>
import Client, { Redirect, shared } from '@shoplinedev/appbridge';

import { getRedirectUrl } from '@/api/plugin';
export default {
  created() {
    this.$store.dispatch('app/setBrandInfo', {});
  },
  mounted() {
    // const { appName, appkey } = this.$route.query;
    // const tryGetRedirectUrl = async () => {
    //   try {
    //     const res = await getRedirectUrl('shopline', appName, this.$route.query);
    //     if (res.respCode === '20000') {
    //       if (res.data) {
    //         setTimeout(() => {
    //           const app = Client.createApp({
    //             appKey: appkey,
    //             host: shared.getHost(),
    //           });
    //           const redirect = Redirect.create(app);
    //           redirect.replaceTo(res.data);
    //         }, 3000);
    //       }
    //     }
    //   } catch (error) {
    //   // 如果请求失败，等待 3 秒后重新请求
    //     console.error('请求失败，3秒后重试...', error);
    //     setTimeout(tryGetRedirectUrl, 3000);
    //   }
    // };

    // tryGetRedirectUrl();
  },
};
</script>
<style lang="scss" scoped>
.app-install-success {
  width: fit-content;
  max-width: 100%;
  margin: 0 auto;
  padding-top: 130px;
  .success {
    width: 134px;
  }
  .congratulations {
    font-size: 24px;
    font-family: Arial-BoldMT, Arial;
    font-weight: normal;
    color: #212B36;
    line-height: 28px;
    margin-top: 28px;
  }
  .tips {
    font-size: 18px;
    color: #616D7A;
    line-height: 21px;
    margin-top: 20px;
    text-align: left;
  }
}
</style>
